import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import "./App.css";
import { GameMode } from "./components/common/interface";
import { usePersistentStatsReducer } from "./components/common/stats-reducer";
import { NavBar } from "./components/NavBar";
import { Game } from "./components/normal-mode/Game";
import { usePersistentSettings } from "./components/settings-reducer";
import { SettingsDialog } from "./components/SettingsDialog";
import { StatsDialog } from "./components/StatsDialog";
import { TimerGame } from "./components/timer-mode/TimerGame";
import { createAppTheme } from "./theme";

export default function App() {
  const [settings, settingsReducer] = usePersistentSettings();

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [gameMode, setGameMode] = useState(GameMode.Normal);
  const theme = React.useMemo(
    () => createAppTheme(settings.darkMode),
    [settings.darkMode]
  );

  const [statsOpen, setStatsOpen] = useState(false);
  const [stats] = usePersistentStatsReducer();

  const handleGameModeChange = (gameMode: GameMode) => {
    setGameMode(gameMode);
  };

  const handleSettingsSelected = () => setSettingsOpen(true);
  const handleSettingsClose = () => setSettingsOpen(false);

  const handleStatsSelected = () => setStatsOpen(true);
  const handleStatsClose = () => setStatsOpen(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <NavBar
        currentGameMode={gameMode}
        onGameModeSelected={handleGameModeChange}
        onSettingsSelected={handleSettingsSelected}
        onStatsSelected={handleStatsSelected}
      />
      <Container
        component="main"
        disableGutters
        sx={{
          px: 1,
          height: `calc(100% - ${theme.spacing(8)})`,
          // height: `calc(100vh - 9em)`,
        }}
      >
        {gameMode === GameMode.Normal && (
          <Game settings={settings} settingsReducer={settingsReducer} />
        )}
        {gameMode === GameMode.Timer && (
          <TimerGame settings={settings} settingsReducer={settingsReducer} />
        )}
      </Container>
      <SettingsDialog
        open={settingsOpen}
        settingsReducer={settingsReducer}
        onClose={handleSettingsClose}
        settings={settings}
      />
      <StatsDialog open={statsOpen} onClose={handleStatsClose} stats={stats} />
    </ThemeProvider>
  );
}
