import useMediaQuery from "@mui/material/useMediaQuery";
import { useCallback, useMemo, useReducer } from "react";
import useLocalStorage from "react-use/lib/useLocalStorage";

type Action =
  | { type: "SET_DARK_MODE"; value: boolean }
  | { type: "SET_SEEN_NORMAL_MODE_TUTORIAL"; value: boolean }
  | { type: "SET_SEEN_TIMER_MODE_TUTORIAL"; value: boolean };

export type Settings = {
  darkMode?: boolean;
  seenNormalModeTutorial?: boolean;
  seenTimerModeTutorial?: boolean;
};

export type SettingsReducerProps = {
  settings: Settings;
  settingsReducer: ISettingsReducer;
};

export function settingsReducer(settings: Settings, action: Action): Settings {
  switch (action.type) {
    case "SET_DARK_MODE":
      return {
        ...settings,
        darkMode: action.value,
      };

    case "SET_SEEN_NORMAL_MODE_TUTORIAL":
      return {
        ...settings,
        seenNormalModeTutorial: action.value,
      };

    case "SET_SEEN_TIMER_MODE_TUTORIAL":
      return {
        ...settings,
        seenTimerModeTutorial: action.value,
      };

    default:
      throw new Error("Unreachable case");
  }
}

export interface ISettingsReducer {
  setDarkMode: (value: boolean) => void;
  setNormalModeTutorial: (value: boolean) => void;
  setTimerModeTutorial: (value: boolean) => void;
}

const SETTINGS_LOCAL_STORAGE_KEY = "settings";

export function usePersistentSettings(): [Settings, ISettingsReducer] {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [settings, setSettings] = useLocalStorage<Settings>(
    SETTINGS_LOCAL_STORAGE_KEY,
    {
      darkMode: prefersDarkMode,
    }
  );
  if (!settings) {
    throw new Error("invalid settings");
  }

  const wrappedReducer = useCallback<typeof settingsReducer>(
    (state, action) => {
      const newState = settingsReducer(state, action);

      setSettings(newState);

      return newState;
    },
    [setSettings]
  );
  const [state, dispatch] = useReducer(wrappedReducer, settings);

  const reducer = useMemo(
    () => ({
      setDarkMode: (value: boolean) =>
        dispatch({ type: "SET_DARK_MODE", value }),

      setNormalModeTutorial: (value: boolean) =>
        dispatch({ type: "SET_SEEN_NORMAL_MODE_TUTORIAL", value }),

      setTimerModeTutorial: (value: boolean) =>
        dispatch({ type: "SET_SEEN_TIMER_MODE_TUTORIAL", value }),
    }),
    [dispatch]
  );

  return [state, reducer];
}
