import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { formatMsTime } from "../../lib/time";

const INTERVAL_MS = 10;

type Props = {
  deadline?: Date;
  onTimerExpired?: () => void;
};

export function CountdownTimer({ deadline, onTimerExpired }: Props) {
  const [remainingMs, setRemainingMs] = useState<number>();
  useEffect(() => {
    if (!deadline) {
      return;
    }

    setRemainingMs(deadline.getTime() - Date.now());

    const interval = setInterval(() => {
      setRemainingMs((s) => {
        if (!s) {
          return;
        }

        const nextS = s - INTERVAL_MS;
        if (nextS > 0) {
          return nextS;
        }

        clearInterval(interval);
        return 0;
      });
    }, INTERVAL_MS);

    return () => clearInterval(interval);
  }, [deadline]);

  useEffect(() => {
    if (remainingMs !== undefined && remainingMs <= 0) {
      onTimerExpired?.();
    }
  }, [remainingMs, onTimerExpired]);

  const time = formatMsTime(remainingMs || 0);

  return <Typography sx={{ fontWeight: "bold" }}>{time}</Typography>;
}
