import * as firebase from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDMdSe64UKbmr5ZJGnohSohJlaPaOSSM0g",
  authDomain: "ragnarok-9a985.firebaseapp.com",
  projectId: "ragnarok-9a985",
  storageBucket: "ragnarok-9a985.appspot.com",
  messagingSenderId: "1077875395742",
  appId: "1:1077875395742:web:c5422b9c6d1b7291a97f8f",
  measurementId: "G-SEF3TDE50X",
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
