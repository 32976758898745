import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { formatMsTime } from "../../lib/time";
import { BoardResult } from "../common/board-state-reducer";
import { GameStats } from "../common/stats-reducer";
import { StatsDashboard } from "../common/StatsDashboard";
import { WordDefinition } from "../common/WordDefinition";

type Props = {
  result: BoardResult;
  goal: string;
  attempt?: number;
  durationMs: number;
  stats: GameStats;

  open: boolean;
  onClose: () => void;
  onPlayAgain: () => void;
};

export function SummaryDialog({
  open,
  onClose,
  onPlayAgain,
  result,
  goal,
  attempt,
  durationMs,
  stats,
}: Props) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Summary</DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography>Word</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <WordDefinition word={goal} />
          </Grid>

          <Grid item xs={6}>
            <Typography>Duration</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography>{formatMsTime(durationMs)}</Typography>
          </Grid>

          {result === BoardResult.Won && (
            <>
              <Grid item xs={6}>
                <Typography>Guess</Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography>
                  {attempt !== undefined ? attempt + 1 : "N/A"}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <StatsDashboard stats={stats} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onPlayAgain}>Play Again?</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
