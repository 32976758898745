import AppsIcon from "@mui/icons-material/Apps";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import TimerIcon from "@mui/icons-material/Timer";
import AppBar from "@mui/material/AppBar";
import Box, { BoxProps } from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { GameMode } from "./common/interface";

type Props = {
  currentGameMode?: GameMode;
  onGameModeSelected: (gameMode: GameMode) => void;
  onSettingsSelected: () => void;
  onStatsSelected: () => void;
};

export function NavBar({
  currentGameMode,
  onGameModeSelected,
  onSettingsSelected,
  onStatsSelected,
}: BoxProps & Props) {
  const handleNormalGameModeSelected = () =>
    onGameModeSelected(GameMode.Normal);
  const handleTimerGameModeSelected = () => onGameModeSelected(GameMode.Timer);

  return (
    <Box sx={{ flexGrow: 1, mb: 1, alignContent: "center" }}>
      <AppBar position="static" color="default">
        <Toolbar>
          <Tooltip title="Normal Mode">
            <IconButton
              onClick={handleNormalGameModeSelected}
              color={
                currentGameMode === GameMode.Normal ? "primary" : undefined
              }
            >
              <AppsIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Timer Mode">
            <IconButton
              onClick={handleTimerGameModeSelected}
              color={currentGameMode === GameMode.Timer ? "primary" : undefined}
            >
              <TimerIcon />
            </IconButton>
          </Tooltip>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center" }}
          >
            Wordle Gym
          </Typography>
          {/* TODO: Statistics overall */}
          <Tooltip title="Statistics">
            <IconButton onClick={onStatsSelected}>
              <BarChartIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Settings">
            <IconButton onClick={onSettingsSelected}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
