import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { flip } from "./animations";
import { Evaluation, EvaluationType, getEvaluationColor } from "./interface";

type Props = {
  guess?: string;
  evaluations: Array<Evaluation>;
  onAnimationEnd?: () => void;
};

const ANIMATION_DELAY_FACTOR_SEC = 0.1;
const ANIMATION_DURATION_SEC = 0.6;

export default function WordRow({ guess, evaluations, onAnimationEnd }: Props) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        verticalAlign: "middle",
        justifyContent: "center",
        textAlign: "center",
        textTransform: "uppercase",
        fontWeight: "bolder",
      }}
    >
      {evaluations.map((evaluation, i) => {
        const colors = getEvaluationColor(theme, evaluation.type);
        const isLast = i === evaluations.length - 1;
        const handleAnimationEnd = isLast ? onAnimationEnd : undefined;
        return (
          <Box
            key={i}
            onAnimationEnd={handleAnimationEnd}
            sx={[
              {
                width: "2em",
                height: "2em",
                borderWidth: "0.05em",
                fontSize: "2em",
                m: 0.3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              },
              evaluation.type === EvaluationType.None && {
                borderStyle: "solid",
                borderColor: theme.palette.game.keyBackground,
              },
              evaluation.type !== EvaluationType.None && {
                animation: `${flip} ${ANIMATION_DURATION_SEC}s ease ${
                  ANIMATION_DELAY_FACTOR_SEC * i
                }s`,
                transitionDelay: `${
                  ANIMATION_DURATION_SEC / 2 + ANIMATION_DELAY_FACTOR_SEC * i
                }s`,
                borderStyle: "none",
                ...colors,
              },
            ]}
          >
            {guess && i < guess.length ? guess.charAt(i) : evaluation.letter}
          </Box>
        );
      })}
    </Box>
  );
}
