import { useMemo, useReducer } from "react";
import { getRandomWord, isValidWord } from "../../wordlist";
import { Evaluation, EvaluationType } from "./interface";

type ErrorMessage = {
  ts: number;
  message: string;
};

type BoardState = {
  error?: ErrorMessage;
  maxAttempt: number;
  goal: string;
  secondaryGoal: string;
  attempt: number;
  guess: string;
  guesses: Array<Array<Evaluation>>;
  presentSet: Set<string>;
  absentSet: Set<string>;
  correctSet: Set<string>;
};

export enum BoardResult {
  None = 0,
  Won = 1 << 0,
  Lost = 1 << 1,
  Completed = Won | Lost,
}

function newState({ maxAttempt, goal }: BoardStateArgs): BoardState {
  // Initialize evaluations
  const length = goal.length;
  const guesses = Array.from(Array(maxAttempt)).map(() => {
    return Array.from(Array(length)).map(() => ({
      letter: "",
      type: EvaluationType.None,
    }));
  });

  // Get a secondary goal that is not the main goal
  let secondaryGoal;
  do {
    secondaryGoal = getRandomWord();
  } while (secondaryGoal === goal);

  return {
    maxAttempt,
    goal,
    secondaryGoal,
    attempt: 0,
    guess: "",
    guesses,
    presentSet: new Set<string>(),
    absentSet: new Set<string>(),
    correctSet: new Set<string>(),
  };
}

function updateMap(
  map: Map<string, number>,
  key: string,
  updateFn: (x: number) => number
) {
  const val = map.get(key) ?? 0;
  map.set(key, updateFn(val));

  return map;
}

function subtract(from: Set<string>, ...others: Array<Set<string>>) {
  // Delete all the keys from each of the other set
  others.forEach((other) => other.forEach((key) => from.delete(key)));
}

function evaluate(
  goal: string,
  guess: string,
  evaluations: Array<Evaluation>
): Array<Evaluation> {
  const counter = Array.from(goal).reduce(
    (acc, char) => updateMap(acc, char, (count) => count + 1),
    new Map<string, number>()
  );

  // First scan: Check for exact match
  for (let i = 0; i < goal.length; i++) {
    const guessLetter = i < guess.length ? guess.charAt(i) : "";
    const goalLetter = goal.charAt(i);
    evaluations[i].letter = guessLetter;

    if (guessLetter === goalLetter) {
      updateMap(counter, goalLetter, (count) => count - 1);
      evaluations[i].type = EvaluationType.Correct;
    }
  }

  // Second scan: Exclude exact match, check for present
  for (let i = 0; i < goal.length; i++) {
    const guessLetter = i < guess.length ? guess.charAt(i) : "";
    const goalLetter = goal.charAt(i);
    if (guessLetter === goalLetter) {
      continue;
    }

    if (counter.get(guessLetter)) {
      updateMap(counter, guessLetter, (count) => count - 1);
      evaluations[i].type = EvaluationType.Present;
    } else {
      evaluations[i].type = EvaluationType.Absent;
    }
  }

  return evaluations;
}

type Action =
  | { type: "BACKSPACE" }
  | { type: "CLEAR_ERROR" }
  | { type: "INPUT"; value: string }
  | ({ type: "RESET" } & BoardStateArgs)
  | { type: "SUBMIT" };

// TODO: Reduce re-render
const WORD_LENGTH = 5;
const MAX_GUESS_LENGTH = 5;

function validateGuess(invalidGuess: string): ErrorMessage | undefined {
  if (invalidGuess.length < WORD_LENGTH) {
    return {
      ts: Date.now(),
      message: "Not enough letters",
    };
  } else if (!isValidWord(invalidGuess)) {
    return {
      ts: Date.now(),
      message: "Not in word list",
    };
  } else {
    return undefined;
  }
}

function boardStateReducer(state: BoardState, action: Action): BoardState {
  switch (action.type) {
    case "RESET":
      return newState({ maxAttempt: action.maxAttempt, goal: action.goal });

    case "BACKSPACE":
      return {
        ...state,
        guess: state.guess.substring(0, state.guess.length - 1),
      };

    case "INPUT":
      return state.guess.length < MAX_GUESS_LENGTH
        ? { ...state, guess: state.guess + action.value }
        : state;

    case "CLEAR_ERROR":
      return { ...state, error: undefined };

    case "SUBMIT": {
      const error = validateGuess(state.guess);
      if (error) {
        return { ...state, error };
      }

      if (state.guess === state.secondaryGoal) {
        console.log("Sike!");
      }

      const evaluatedGuess = evaluate(
        state.goal,
        state.guess,
        state.guesses[state.attempt]
      );
      const newState = {
        ...state,
        absentSet: new Set(state.absentSet),
        presentSet: new Set(state.presentSet),
        correctSet: new Set(state.correctSet),
        attempt: state.attempt + 1,
        guesses: [...state.guesses, evaluatedGuess],
        guess: "",
      };
      evaluatedGuess.forEach((evaluation) => {
        switch (evaluation.type) {
          case EvaluationType.Absent:
            newState.absentSet.add(evaluation.letter);
            break;

          case EvaluationType.Present:
            newState.presentSet.add(evaluation.letter);
            break;

          case EvaluationType.Correct:
            newState.correctSet.add(evaluation.letter);
            break;
        }
      });

      // TODO: More elegant approach to remove duplicated from different sets
      subtract(newState.presentSet, newState.correctSet);
      subtract(newState.absentSet, newState.correctSet, newState.presentSet);

      return newState;
    }
  }
}

type BoardStateArgs = {
  maxAttempt: number;
  goal: string;
};

interface IBoardStateReducer {
  input(value: string): void;
  backspace(): void;
  reset({ maxAttempt, goal }: BoardStateArgs): void;
  submit(): void;
  clearError(): void;
}

export function useBoardState(
  args: BoardStateArgs
): [BoardState, IBoardStateReducer] {
  const [state, dispatch] = useReducer(boardStateReducer, args, newState);
  const reducer = useMemo(
    () => ({
      input: (value: string) => dispatch({ type: "INPUT", value }),
      backspace: () => dispatch({ type: "BACKSPACE" }),
      reset: (args: BoardStateArgs) => dispatch({ type: "RESET", ...args }),
      submit: () => dispatch({ type: "SUBMIT" }),
      clearError: () => dispatch({ type: "CLEAR_ERROR" }),
    }),
    [dispatch]
  );
  return [state, reducer];
}
