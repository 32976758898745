import { css, cx } from "@emotion/css";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import { Theme, useTheme } from "@mui/material/styles";
import React, { useCallback, useEffect, useRef } from "react";
import Keyboard, { KeyboardReactInterface } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useKeyboardTheme } from "../../theme";
import { BoardResult, useBoardState } from "./board-state-reducer";
import { EvaluationType, getEvaluationColor } from "./interface";
import WordRow from "./WordRow";

type BoardProps = {
  maxAttempt: number;
  goal: string;
  readonly: boolean;
  onCompleted: (result: BoardResult, attempt?: number) => void;
};

function toSpaceSeparated(set: Set<string>): string {
  return Array.from(set.values()).join(" ");
}

function isLetter(input: string): boolean {
  return /^[a-z]$/.test(input);
}

function getEvaluationCss(theme: Theme, evaluationType: EvaluationType) {
  const colors = getEvaluationColor(theme, evaluationType);
  if (!colors) {
    return "";
  }

  return css({
    "&&.hg-button": {
      ...colors,
    },
  });
}

export function Board({ onCompleted, maxAttempt, goal, readonly }: BoardProps) {
  const theme = useTheme();
  const keyboardTheme = useKeyboardTheme();
  const [state, stateReducer] = useBoardState({ maxAttempt, goal });
  const keyboard = useRef<KeyboardReactInterface | null>(null);
  console.log(
    "Hey, that's cheating! Anyway the solution is:",
    state.secondaryGoal
  );

  const { correctSet, presentSet, absentSet } = state;

  useEffect(() => {
    // Update the keyboard layout
    keyboard.current?.removeButtonTheme("", "");
    keyboard.current?.addButtonTheme(
      toSpaceSeparated(correctSet),
      getEvaluationCss(theme, EvaluationType.Correct)
    );
    keyboard.current?.addButtonTheme(
      toSpaceSeparated(presentSet),
      getEvaluationCss(theme, EvaluationType.Present)
    );
    keyboard.current?.addButtonTheme(
      toSpaceSeparated(absentSet),
      getEvaluationCss(theme, EvaluationType.Absent)
    );
  }, [correctSet, presentSet, absentSet, keyboard, theme]);

  const handleInput = useCallback(
    (arg: string | KeyboardEvent) => {
      if (readonly) {
        return;
      }

      const value = typeof arg === "string" ? arg : arg.key;

      if (value === "{enter}" || value === "Enter") {
        stateReducer.submit();
      } else if (value === "{backspace}" || value === "Backspace") {
        stateReducer.backspace();
      } else if (isLetter(value)) {
        stateReducer.input(value);
      }
    },
    [readonly, stateReducer]
  );

  useEffect(() => {
    if (!readonly) {
      document.addEventListener("keydown", handleInput);
    }

    return () => {
      document.removeEventListener("keydown", handleInput);
    };
  }, [handleInput, readonly]);

  useEffect(() => {
    // Reset
    stateReducer.reset({ maxAttempt, goal });
    keyboard.current?.removeButtonTheme("", "");
  }, [keyboard, stateReducer, maxAttempt, goal]);

  const handleCompletionEvaluation = useCallback(() => {
    const lastGuess = state.guesses[state.guesses.length - 1];
    if (
      lastGuess.every(
        (evaluation) => evaluation.type === EvaluationType.Correct
      )
    ) {
      onCompleted(BoardResult.Won, state.attempt - 1);
    } else if (state.attempt === maxAttempt) {
      onCompleted(BoardResult.Lost);
    }
  }, [maxAttempt, onCompleted, state.attempt, state.guesses]);

  // Typing captured to current WordRow being attempted
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    stateReducer.clearError();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      {state.error && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={state.error.message}
          ContentProps={{
            sx: {
              justifyContent: "center",
            },
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexGrow: 1,
          overflow: "hidden",
          justifyContent: "center",
          m: 0,
        }}
      >
        {Array.from({ length: maxAttempt }).map((_, i) => {
          const currentGuess = i === state.attempt ? state.guess : undefined;
          return (
            <WordRow
              key={i}
              guess={currentGuess}
              evaluations={state.guesses[i]}
              onAnimationEnd={handleCompletionEvaluation}
            />
          );
        })}
      </Box>
      <Box>
        <Keyboard
          keyboardRef={(r) => (keyboard.current = r)}
          theme={cx("hg-theme-default", keyboardTheme)}
          layout={{
            default: [
              "q w e r t y u i o p",
              "a s d f g h j k l",
              "{enter} z x c v b n m {backspace}",
            ],
          }}
          layoutName="default"
          onKeyPress={handleInput}
          display={{
            "{enter}": "enter",
            "{backspace}": "⌫",
          }}
        />
      </Box>
    </Box>
  );
}
