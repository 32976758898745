import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { slideUpThenFade } from "../common/animations";
import { Evaluation, EvaluationType } from "../common/interface";
import WordRow from "../common/WordRow";
import { TimeChangeFeedback } from "./TimeChangeFeedback";

const example1: Array<Evaluation> = [
  { letter: "w", type: EvaluationType.Correct },
  { letter: "e", type: EvaluationType.None },
  { letter: "a", type: EvaluationType.None },
  { letter: "r", type: EvaluationType.None },
  { letter: "y", type: EvaluationType.None },
];

const example2: Array<Evaluation> = [
  { letter: "p", type: EvaluationType.None },
  { letter: "i", type: EvaluationType.Present },
  { letter: "l", type: EvaluationType.None },
  { letter: "l", type: EvaluationType.None },
  { letter: "s", type: EvaluationType.None },
];

const example3: Array<Evaluation> = [
  { letter: "v", type: EvaluationType.None },
  { letter: "a", type: EvaluationType.None },
  { letter: "g", type: EvaluationType.None },
  { letter: "u", type: EvaluationType.Absent },
  { letter: "e", type: EvaluationType.None },
];

export function Tutorial(props: BoxProps) {
  return (
    <Box {...props}>
      <Typography textAlign="center" variant="h6" component="h6" gutterBottom>
        How to play
      </Typography>
      <Typography variant="body2" gutterBottom>
        Guess the <strong>WORDLE</strong> in six tries. Guess as many as
        possible in <strong>5 minutes</strong>.
      </Typography>
      <Typography variant="body2" gutterBottom>
        Each guess must be a valid five-letter word. Hit the{" "}
        <strong>ENTER</strong> button to submit.
      </Typography>
      <Typography variant="body2" gutterBottom>
        After each guess, the color of the tiles will change to show how close
        your guess was to the word.
      </Typography>
      <hr />
      <Typography variant="subtitle1" gutterBottom>
        The twist
      </Typography>
      <Typography variant="body2" gutterBottom>
        When a word is successfully guessed, more time will be added to the
        timer. The smaller the number of guesses is, the more time is added.
      </Typography>
      <TimeChangeFeedback
        sx={{
          animation: `${slideUpThenFade} 1.2s infinite`,
        }}
        timeChangeMs={50 * 1000}
      />
      <Typography variant="body2" gutterBottom>
        A time penalty of 30 seconds will be deducted if a word is not guessed
        after six tries.
      </Typography>
      <TimeChangeFeedback
        sx={{
          animation: `${slideUpThenFade} 1.2s infinite`,
          animationDelay: "0.6s",
        }}
        timeChangeMs={-30 * 1000}
      />
      <hr />
      <Typography variant="subtitle1" gutterBottom>
        Examples
      </Typography>
      <WordRow evaluations={example1} />
      <Typography variant="body2" gutterBottom>
        The letter <strong>W</strong> is in the word and in the correct spot.
      </Typography>
      <WordRow evaluations={example2} />
      <Typography variant="body2" gutterBottom>
        The letter <strong>I</strong> is in the word but in the wrong spot.
      </Typography>
      <WordRow evaluations={example3} />
      <Typography variant="body2" gutterBottom>
        The letter <strong>U</strong> is not in the word in any spot.
      </Typography>
      <hr />
      <Typography variant="caption">
        Credit to New York Times Wordle.
      </Typography>
    </Box>
  );
}
