import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { formatMsTime } from "../../lib/time";
import { GameStats } from "../common/stats-reducer";
import { StatsDashboard } from "../common/StatsDashboard";
import { WordDefinition } from "../common/WordDefinition";
import { Result } from "./TimerGame";

type Props = {
  results: Array<Result>;
  startTs: Date;
  endTs: Date;
  stats: GameStats;

  open: boolean;
  onClose: () => void;
  onPlayAgain: () => void;
};

export function SummaryDialog({
  open,
  onClose,
  onPlayAgain,
  results,
  endTs,
  startTs,
  stats,
}: Props) {
  const durationMs = endTs.getTime() - startTs.getTime();
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Summary</DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography>Total Duration</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography>{formatMsTime(durationMs)}</Typography>
          </Grid>
        </Grid>
        <StatsDashboard stats={stats} />
        {results && (
          <TableContainer sx={{ width: "100%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Word</TableCell>
                  <TableCell align="center">Guess</TableCell>
                  <TableCell align="right">Time Taken</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result, i) => {
                  const timeTakenMs =
                    result.endTs.getTime() -
                    (i === results.length - 1
                      ? startTs.getTime()
                      : results[i + 1].endTs.getTime());
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <WordDefinition word={result.goal} />
                      </TableCell>
                      <TableCell align="center">
                        {result.attempt ?? "-"}
                      </TableCell>
                      <TableCell align="right">
                        {formatMsTime(timeTakenMs)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onPlayAgain}>Play Again?</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
