import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import { useCallback, useEffect, useState } from "react";
import { getRandomWord } from "../../wordlist";
import { slideUpThenFade } from "../common/animations";
import { Board } from "../common/Board";
import { BoardResult } from "../common/board-state-reducer";
import { useTrasientStatsReducer } from "../common/stats-reducer";
import { TutorialDialog } from "../common/TutorialDialog";
import { SettingsReducerProps } from "../settings-reducer";
import { CountdownTimer } from "./CountdownTimer";
import { SummaryDialog } from "./SummaryDialog";
import { TimeChangeFeedback } from "./TimeChangeFeedback";
import { Tutorial } from "./Tutorial";

const MAX_ATTEMPT = 6;
const MS_PER_SECONDS = 1000;
const INITIAL_TIMER_MS = 5 * 60 * 1000; // 5 minutes

export type Result = {
  goal: string;
  boardResult: BoardResult;
  attempt?: number;
  endTs: Date;
  timeChangeMs: number;
};

type GameState = {
  goal: string;
  gameOver: boolean;
  deadlineTs: Date;
  startTs: Date;
  endTs?: Date;
  summaryOpen: boolean;
  results: Array<Result>;
};

function createNewState(): GameState {
  const start = new Date();
  return {
    goal: getRandomWord(),
    deadlineTs: new Date(start.getTime() + INITIAL_TIMER_MS),
    gameOver: false,
    summaryOpen: false,
    startTs: start,
    results: [],
  };
}

export function TimerGame({ settings, settingsReducer }: SettingsReducerProps) {
  const [state, setState] = useState<GameState>(createNewState());
  const [stats, statsReducer] = useTrasientStatsReducer();

  const handleBoardCompleted = useCallback(
    (result: BoardResult, attempt?: number) => {
      let timeChange = 0;
      switch (result) {
        case BoardResult.Won:
          if (attempt === undefined) {
            throw new Error("invalid attempt when completing.");
          }
          statsReducer.incrementWins(attempt);
          timeChange = (MAX_ATTEMPT - attempt) * 10 * MS_PER_SECONDS;
          break;

        case BoardResult.Lost:
          statsReducer.incrementLosses();
          timeChange = -30 * MS_PER_SECONDS;
          break;
      }

      setState((s) => ({
        ...s,
        deadlineTs: new Date(s.deadlineTs.getTime() + timeChange),
        goal: getRandomWord(),
        results: [
          {
            goal: s.goal,
            boardResult: result,
            attempt,
            endTs: new Date(),
            timeChangeMs: timeChange,
          },
          ...s.results,
        ],
      }));
    },
    [statsReducer]
  );

  const handleTimerExpired = useCallback(() => {
    setState((s) => ({
      ...s,
      gameOver: true,
      previousGoal: s.goal,
      previousResult: BoardResult.Lost,
      endTs: new Date(),
      results: [
        {
          goal: s.goal,
          boardResult: BoardResult.Lost,
          endTs: new Date(),
          timeChangeMs: 0,
        },
        ...s.results,
      ],
    }));
  }, []);

  const handleReset = useCallback(() => {
    setState(createNewState());
    statsReducer.reset();
  }, [statsReducer]);

  const handleKeydownForReset = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "Space") {
        handleReset();
      }
    },
    [handleReset]
  );

  const handleSummaryOpen = () =>
    setState((s) => ({ ...s, summaryOpen: true }));

  const handleSummaryClose = () =>
    setState((s) => ({ ...s, summaryOpen: false }));

  const {
    deadlineTs: deadline,
    endTs,
    gameOver,
    goal,
    results,
    startTs,
    summaryOpen,
  } = state;

  useEffect(() => {
    if (gameOver) {
      document.addEventListener("keydown", handleKeydownForReset);
    }
    return () => document.removeEventListener("keydown", handleKeydownForReset);
  }, [gameOver, handleKeydownForReset]);

  const handleTutorialDialogClose = useCallback(() => {
    settingsReducer.setTimerModeTutorial(true);
    handleReset();
  }, [settingsReducer, handleReset]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", height: 1 }}>
      <TutorialDialog
        open={!settings.seenTimerModeTutorial}
        onClose={handleTutorialDialogClose}
        title="Timer Mode"
      >
        <Tutorial />
      </TutorialDialog>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          width: 1,
          maxWidth: "500px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container>
          <Grid item xs={3} />
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
            <CountdownTimer
              deadline={settings.seenTimerModeTutorial ? deadline : undefined}
              onTimerExpired={handleTimerExpired}
            />
          </Grid>
          {state.results.length >= 1 && (
            <Grid item xs={3}>
              <TimeChangeFeedback
                key={state.results.length}
                sx={{
                  animation: `${slideUpThenFade} 1.2s ease`,
                  animationFillMode: "forwards",
                }}
                timeChangeMs={state.results[0].timeChangeMs}
              />
            </Grid>
          )}
        </Grid>

        <Box sx={{ width: 1, height: 1, mb: 1 }}>
          <Board
            goal={goal}
            maxAttempt={MAX_ATTEMPT}
            onCompleted={handleBoardCompleted}
            readonly={gameOver}
          />
        </Box>

        {gameOver && (
          <Fab
            variant="extended"
            color="primary"
            onClick={handleSummaryOpen}
            sx={{
              position: "fixed",
              bottom: "50%",
              left: "calc((100% - 15em)/2)",
              width: "15em",
            }}
          >
            {"View Result"}
          </Fab>
        )}

        {endTs && (
          <SummaryDialog
            startTs={startTs}
            endTs={endTs}
            onClose={handleSummaryClose}
            onPlayAgain={handleReset}
            results={results}
            stats={stats}
            open={summaryOpen}
          />
        )}
      </Box>
    </Box>
  );
}
