import { css } from "@emotion/css";
import { createTheme, Theme, useTheme } from "@mui/material/styles";
import React, { useMemo } from "react";

type GamePalette = {
  absent: NonNullable<React.CSSProperties["color"]>;
  present: NonNullable<React.CSSProperties["color"]>;
  correct: NonNullable<React.CSSProperties["color"]>;
  keyBackground: NonNullable<React.CSSProperties["color"]>;
  keyTextColor: NonNullable<React.CSSProperties["color"]>;
  keyEvaluatedTextColor: NonNullable<React.CSSProperties["color"]>;
};

declare module "@mui/material/styles" {
  interface Palette {
    game: GamePalette;
  }

  interface PaletteOptions {
    game: GamePalette;
  }
}

function getGamePalette(darkMode?: boolean): GamePalette {
  return darkMode
    ? {
        absent: "#3a3a3c",
        present: "#b59f3b",
        correct: "#538d4e",
        keyBackground: "#818384",
        keyTextColor: "#fff",
        keyEvaluatedTextColor: "#fff",
      }
    : {
        absent: "#787c7e",
        present: "#c9b458",
        correct: "#6aaa64",
        keyBackground: "#d3d6da",
        keyTextColor: "#000",
        keyEvaluatedTextColor: "#fff",
      };
}

export function createAppTheme(darkMode?: boolean): Theme {
  return createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      game: getGamePalette(darkMode),
    },
  });
}

export function useKeyboardTheme() {
  const theme = useTheme();

  const keyboardTheme = useMemo(() => {
    return css({
      color: theme.palette.game.keyTextColor,
      backgroundColor: "transparent",
      textTransform: "uppercase",
      fontWeight: "bold",
      "& .hg-button": {
        height: "3em",
        borderBottom: "none",
        backgroundColor: theme.palette.game.keyBackground,
      },
      "& .hg-button.hg-activeButton": {
        backgroundColor: theme.palette.game.keyBackground,
      },
    });
  }, [theme]);

  return keyboardTheme;
}
