import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { useCallback, useEffect, useState } from "react";
import { getRandomWord } from "../../wordlist";
import { Board } from "../common/Board";
import { BoardResult } from "../common/board-state-reducer";
import { usePersistentStatsReducer } from "../common/stats-reducer";
import { TutorialDialog } from "../common/TutorialDialog";
import { SettingsReducerProps } from "../settings-reducer";
import { SummaryDialog } from "./SummaryDialog";
import { Tutorial } from "./Tutorial";

const maxAttempt = 6;

type GameState = {
  goal: string;
  result: BoardResult;
  attempt?: number;
  summaryOpen: boolean;
  startTs: Date;
  endTs?: Date;
};

function newGameState(): GameState {
  return {
    goal: getRandomWord(),
    result: BoardResult.None,
    summaryOpen: false,
    startTs: new Date(),
  };
}

export function Game({ settings, settingsReducer }: SettingsReducerProps) {
  const [state, setState] = useState<GameState>(newGameState());
  const [stats, statsReducer] = usePersistentStatsReducer();

  const handleReset = useCallback(() => {
    if (state.result & BoardResult.Completed) {
      setState(newGameState());
    }
  }, [state.result]);

  const handleSummaryOpen = () =>
    setState((s) => ({ ...s, summaryOpen: true }));
  const handleSummaryClose = () =>
    setState((s) => ({ ...s, summaryOpen: false }));

  const handleKeydownForReset = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "Space") {
        handleReset();
      }
    },
    [handleReset]
  );

  useEffect(() => {
    if (state.result & BoardResult.Completed) {
      document.addEventListener("keydown", handleKeydownForReset);
    }
    return () => document.removeEventListener("keydown", handleKeydownForReset);
  }, [handleKeydownForReset, state.result]);

  const handleBoardCompleted = useCallback(
    (result: BoardResult, attempt?: number) => {
      switch (result) {
        case BoardResult.Won:
          if (attempt === undefined) {
            throw new Error("invalid attempt when completing.");
          }
          statsReducer.incrementWins(attempt);
          break;

        case BoardResult.Lost:
          statsReducer.incrementLosses();
          break;
      }
      setState((s) => ({
        ...s,
        summaryOpen: true,
        result,
        attempt,
        endTs: s.endTs ?? new Date(),
      }));
    },
    [statsReducer]
  );

  const handleTutorialDialogClose = useCallback(
    () => settingsReducer.setNormalModeTutorial(true),
    [settingsReducer]
  );

  const gameOver = !!(state.result & BoardResult.Completed);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", height: 1 }}>
      <TutorialDialog
        open={!settings.seenNormalModeTutorial}
        onClose={handleTutorialDialogClose}
        title="Normal Mode"
      >
        <Tutorial />
      </TutorialDialog>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          width: 1,
          maxWidth: "500px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: 1, height: 1, mb: 1 }}>
          <Board
            goal={state.goal}
            maxAttempt={maxAttempt}
            readonly={!!(state.result & BoardResult.Completed)}
            onCompleted={handleBoardCompleted}
          />
        </Box>

        {gameOver && (
          <>
            <SummaryDialog
              open={state.summaryOpen}
              onClose={handleSummaryClose}
              onPlayAgain={handleReset}
              stats={stats}
              goal={state.goal}
              result={state.result}
              attempt={state.attempt}
              durationMs={
                state.endTs
                  ? state.endTs.getTime() - state.startTs.getTime()
                  : 0
              }
            />
            <Fab
              variant="extended"
              color="primary"
              onClick={handleSummaryOpen}
              sx={{
                position: "fixed",
                bottom: "50%",
                left: "calc((100% - 15em)/2)",
                width: "15em",
              }}
            >
              {"View Result"}
            </Fab>
          </>
        )}
      </Box>
    </Box>
  );
}
