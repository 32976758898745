import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactNode } from "react";

type TutorialDialogProps = {
  open: boolean;
  title?: string;
  onClose?: () => void;
  children: ReactNode;
};

export function TutorialDialog({
  open,
  onClose,
  title,
  children,
  ...props
}: DialogProps & TutorialDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth {...props}>
      {!!title && (
        <DialogTitle sx={{ backgroundColor: "background.paper" }}>
          {title}
        </DialogTitle>
      )}

      <DialogContent sx={{ backgroundColor: "background.paper" }}>
        {children}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "background.paper" }}>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
