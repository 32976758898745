import { useCallback, useMemo, useReducer } from "react";
import { useLocalStorage } from "react-use";

const MAX_GUESSES = 6;
const LOCAL_STORAGE_KEY = "stats";
const DEFAULT_VERSION = "v1";

export type GameStats = {
  apiVersion: string;
  played: number;
  wins: number;
  currentStreak: number;
  maxStreak: number;
  guesses: Array<number>;
};

export interface IStatsReducer {
  incrementWins(attempt: number): void;
  incrementLosses(): void;
  reset(): void;
}

const initialState: GameStats = {
  apiVersion: DEFAULT_VERSION,
  played: 0,
  wins: 0,
  currentStreak: 0,
  maxStreak: 0,
  guesses: Array.from(Array(MAX_GUESSES)).map(() => 0),
};

type Action =
  | { type: "INCREMENT_WINS"; attempt: number }
  | { type: "INCREMENT_LOSSES" }
  | { type: "RESET" };

export function statsReducer(state: GameStats, action: Action): GameStats {
  switch (action.type) {
    case "INCREMENT_WINS":
      return {
        ...state,
        played: state.played + 1,
        wins: state.wins + 1,
        currentStreak: state.currentStreak + 1,
        maxStreak: Math.max(state.maxStreak, state.currentStreak + 1),
        guesses: state.guesses.map((val, i) =>
          action.attempt === i ? val + 1 : val
        ),
      };

    case "INCREMENT_LOSSES":
      return {
        ...state,
        played: state.played + 1,
        currentStreak: 0,
      };

    case "RESET":
      return { ...initialState };

    default:
      return state;
  }
}

export function useTrasientStatsReducer(): [GameStats, IStatsReducer] {
  const [state, dispatch] = useReducer(statsReducer, initialState);
  const reducer = useMemo(
    () => ({
      incrementWins: (attempt: number) =>
        dispatch({ type: "INCREMENT_WINS", attempt }),

      incrementLosses: () => dispatch({ type: "INCREMENT_LOSSES" }),

      reset: () => dispatch({ type: "RESET" }),
    }),
    [dispatch]
  );

  return [state, reducer];
}

export function usePersistentStatsReducer(): [GameStats, IStatsReducer] {
  const [stats, setStats] = useLocalStorage(LOCAL_STORAGE_KEY, initialState);
  if (!stats) {
    throw new Error("invalid stats");
  }

  const wrappedReducer = useCallback<typeof statsReducer>(
    (state, action) => {
      const newState = statsReducer(state, action);

      setStats(newState);

      return newState;
    },
    [setStats]
  );
  const [state, dispatch] = useReducer(wrappedReducer, stats);

  const reducer = useMemo(
    () => ({
      incrementWins: (attempt: number) =>
        dispatch({ type: "INCREMENT_WINS", attempt }),

      incrementLosses: () => dispatch({ type: "INCREMENT_LOSSES" }),

      reset: () => dispatch({ type: "RESET" }),
    }),
    [dispatch]
  );

  return [state, reducer];
}
