import { SxProps, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

type TimeChangeFeedbackProps = {
  timeChangeMs: number;
  sx?: SxProps<Theme>;
};

const MS_PER_SECONDS = 1000;

export function TimeChangeFeedback({
  sx = [],
  timeChangeMs,
}: TimeChangeFeedbackProps) {
  if (timeChangeMs >= 0) {
    return (
      <Typography
        sx={[
          {
            color: "success.main",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {`+${Math.floor(timeChangeMs / MS_PER_SECONDS)}s`}
      </Typography>
    );
  }

  return (
    <Typography
      sx={[
        {
          color: "error.main",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {`${Math.floor(timeChangeMs / MS_PER_SECONDS)}s`}
    </Typography>
  );
}
