import { Theme } from "@mui/material/styles";

export enum EvaluationType {
  None,
  Absent,
  Present,
  Correct,
}

export type Evaluation = {
  letter: string;
  type: EvaluationType;
};

export enum GameMode {
  Normal,
  Timer,
}

export type Guess = Array<Evaluation>;

export function getEvaluationColor(
  theme: Theme,
  evaluationType: EvaluationType
): { backgroundColor: string; color: string } | undefined {
  switch (evaluationType) {
    case EvaluationType.Absent:
      return {
        backgroundColor: theme.palette.game.absent,
        color: theme.palette.game.keyEvaluatedTextColor,
      };

    case EvaluationType.Present:
      return {
        backgroundColor: theme.palette.game.present,
        color: theme.palette.game.keyEvaluatedTextColor,
      };

    case EvaluationType.Correct:
      return {
        backgroundColor: theme.palette.game.correct,
        color: theme.palette.game.keyEvaluatedTextColor,
      };

    default:
      return undefined;
  }
}
