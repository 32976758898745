import { keyframes } from "@emotion/react";

export const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`;

/**
 * Slide up (1em) for half the duration, then fade out for the remaining half
 */
export const slideUpThenFade = keyframes`
  from {
    transform: translate3d(0, 0.5em, 0);
    opacity: 1;
  }

  80% {
    transform: translate3d(0, -0.5em, 0);
  }

  to {
    transform: translate3d(0, -0.5em, 0);
    opacity: 0;
  }
`;

export const flip = keyframes`
 from, to {
   transform: rotateX(0deg) rotateY(0deg);
 }

 50% {
   transform: rotateX(90.1deg) rotateY(0deg);
 }
`;

export const trailingFlip = keyframes`
 from, 50%, to {
   transform: rotateX(0deg) rotateY(0deg);
 }

 25% {
   transform: rotateX(90deg) rotateY(0deg);
 }
`;
