const MS_PER_SECONDS = 1000;
const MS_PER_MINUTES = MS_PER_SECONDS * 60;
const MS_PER_HOURS = MS_PER_MINUTES * 60;

export function padNumber(num: number, length: number): string {
  return String(num).padStart(length, "0");
}

export function formatTime(
  hours: number,
  minutes: number,
  seconds: number,
  milliseconds: number
): string {
  return `${hours}:${padNumber(minutes, 2)}:${padNumber(
    seconds,
    2
  )}:${padNumber(milliseconds, 2)}`;
}

export function formatMsTime(ms: number) {
  const hours = Math.floor(ms / MS_PER_HOURS);
  const minutes = Math.floor((ms % MS_PER_HOURS) / MS_PER_MINUTES);
  const seconds = Math.floor((ms % MS_PER_MINUTES) / MS_PER_SECONDS);
  const milliseconds = Math.floor((ms % MS_PER_SECONDS) / 10);

  return formatTime(hours, minutes, seconds, milliseconds);
}
