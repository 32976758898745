import Link from "@mui/material/Link";
import Typography, { TypographyProps } from "@mui/material/Typography";

type Props = {
  word: string;
};

export function WordDefinition({ word, ...props }: TypographyProps & Props) {
  return (
    <Typography {...props}>
      <Link
        href={`https://google.com/search?q=${word}+definition`}
        rel="noreferrer"
        target="_blank"
      >
        {word}
      </Link>
    </Typography>
  );
}
