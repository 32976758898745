import Box from "@mui/material/Box";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { GameStats } from "./stats-reducer";

type StatsDashboardProps = {
  stats: GameStats;
};

function calculatePercentage(count: number, total: number): number {
  if (count === 0) {
    return 0;
  }

  return Math.round((count / total) * 100);
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; label?: string }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {props.label ? props.label : `${Math.round(props.value)}`}
        </Typography>
      </Box>
    </Box>
  );
}

export function StatsDashboard({ stats }: StatsDashboardProps) {
  const mostAttempt = stats.guesses.reduce(
    (prev, curr) => Math.max(prev, curr),
    0
  );
  return (
    <Box sx={{ textAlign: "center" }}>
      <TableContainer sx={{ width: 1, overflow: "hidden", mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Played</TableCell>
              <TableCell>Win %</TableCell>
              <TableCell>Current Streak</TableCell>
              <TableCell>Max Streak</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{stats.played}</TableCell>
              <TableCell>
                {calculatePercentage(stats.wins, stats.played)}
              </TableCell>
              <TableCell>{stats.currentStreak}</TableCell>
              <TableCell>{stats.maxStreak}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6">Guess distribution</Typography>
      <List>
        {stats.guesses.map((val, i) => {
          const percentage = calculatePercentage(val, mostAttempt);
          return (
            <ListItem key={i}>
              <ListItemText primary={`${i + 1}`} />
              <Box sx={{ ml: 1, width: "100%" }}>
                <LinearProgressWithLabel
                  variant="determinate"
                  color="success"
                  value={percentage}
                  label={String(val)}
                  sx={{
                    height: "1em",
                    backgroundColor: "initial",
                  }}
                />
              </Box>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
